<template>
  <div>
    <base-card class="pb-2 rounded-lg">
      <v-tabs v-model="tab" show-arrows class="rounded-t-lg" grow>
        <v-tab v-for="tab in tabs" class="rounded-t-lg" :key="tab.component">
          <v-icon left small>{{ tab.icon }}</v-icon>
          {{ tab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabs" :key="item.name">
          <component
            :is="item.component"
            :data="data"
            :loading="loading"
            :permissions="permissions"
            class="px-4 pb-4"
          />
        </v-tab-item>
      </v-tabs-items>
    </base-card>

    <TecnicianDaily position="1" />

    <organization-member @success="getMembers" />
    <organization-link-organization @success="getMembers" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ORGANIZATION from "@/services/admin/organization";

import Users from "./members/Users.vue";
import Organizations from "./members/Organizations.vue";

import OrganizationMember from "@/components/admin/modals/OrganizationMember.vue";
import OrganizationLinkOrganization from "@/components/admin/modals/OrganizationLinkOrganization.vue";
import TecnicianDaily from "@/components/global/tecnician/TecnicianDaily.vue";

export default {
  components: {
    OrganizationMember,
    OrganizationLinkOrganization,
    Users,
    Organizations,
    TecnicianDaily,
  },
  metaInfo: {
    title: "Membros",
  },
  data() {
    return {
      loading: false,
      data: {
        members: [],
        organizations: [],
      },
      permissions: null,
      tab: null,
      tabs: [
        {
          id: "users",
          name: "Membros",
          component: "Users",
          icon: "mdi-account",
        },
        {
          id: "granting",
          name: "Organizações",
          component: "Organizations",
          icon: "mdi-account-group",
        },
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["getPermissions"]),
    decomposePermissions(permissions) {
      let result = [];
      Object.keys(this.permissions).forEach((p) => {
        if ((permissions & p) == p) result.push(p);
      });
      return result;
    },
    async getMembers() {
      try {
        this.loading = true;
        var response = await ORGANIZATION.members(this.selectedOrganization.id);
        this.data.members = response.users.map((u) => ({
          ...u,
          permissions: this.decomposePermissions(u.permission),
        }));
        this.data.organizations = response.organizations.map((o) => ({
          ...o,
          permissions: this.decomposePermissions(o.permission),
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getPerms() {
      try {
        this.loading = true;
        let response = await this.getPermissions();
        this.permissions = response.permissions;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.tabs.findIndex((tab) => tab.id == hash);
        if (index) this.tab = index;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    numberOfPages() {
      return Math.ceil(this.members.length / this.itemsPerPage);
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.data = {
        members: [],
        organizations: [],
      };
      this.getMembers();
    },
    "$route.hash"() {
      this.selectTab();
    },

    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabs[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.getPerms();
    await this.getMembers();
  },
};
</script>
